import {Moment} from "moment";
import {ChangeItem} from "./ChangeItem";
import {ProductDetails} from "./Product";
import {AddonDetails} from "./Addon";
import {BillingSchedule} from "./BillingSchedule";

export interface Plan {
    id: string;
    name: string;
    businessId: string;
    productGroupId: string;
    productDetails: ProductDetails;
    addonDetailsList: AddonDetails[];
    status: PlanStatus;
    schedule: BillingSchedule;
    createdAt: Moment;
    updatedAt: Moment;
    changes: ChangeItem[];
    createdBy: string;
}

export enum PlanStatus {
    Inactive = 'Inactive',
    PendingPayment = 'PendingPayment',
    PaymentFailed = 'PaymentFailed',
    Active = 'Active',
    Ending = 'Ending',
    Ended = 'Ended'
}
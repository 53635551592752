import {
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiFieldPassword,
  EuiButton, EuiText, EuiLink
} from "@elastic/eui";
import { FC, useState } from "react";
import { useAuthenticator } from "authentication/useAuthenticator";
import {ForgotPasswordModal} from "./users/ForgotPasswordModal";

export const LoginPanel: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login, isLoading } = useAuthenticator();
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  const onLoginClick = () => {
    login(email, password, onLoginSuccess);
  };

  const onLoginSuccess = () => {};

  const onForgotPasswordClick = () => {
    setShowForgotPasswordModal(true);
  }

  return (
    <EuiPanel>
      <>
        <EuiFormRow label={"Email"}>
          <EuiFieldText
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label={"Password"}>
          <EuiFieldPassword
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton
            isDisabled={!email || !password}
            onClick={onLoginClick}
            isLoading={isLoading}
          >
            Login
          </EuiButton>
        </EuiFormRow>
        <EuiFormRow>
            <EuiLink onClick={onForgotPasswordClick}><EuiText size={'s'}>Forgot Password?</EuiText></EuiLink>
        </EuiFormRow>
        {
            showForgotPasswordModal && <ForgotPasswordModal onCancel={() => setShowForgotPasswordModal(false)} />
        }
      </>
    </EuiPanel>
  );
};

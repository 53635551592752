import { defaultTransformFn, dsApi } from "./DsApi";
import { User } from "../entities/User";
import {UserRoleType} from "authentication/UserRoleType";

const usersTags = dsApi.enhanceEndpoints({ addTagTypes: ["Users"] });

const usersApi = usersTags.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<User[], void>({
      query: () => {
        return {
          url: `users`,
          method: "GET",
        };
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: "Users" }],
    }),
    createUser: build.mutation<
      User,
      { email: string; firstName: string; lastName: string; password: string }
    >({
      query: (user) => ({
        url: `users`,
        method: "POST",
        data: {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          password: user.password,
        },
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: [{ type: "Users" }],
    }),
    updateUserRole: build.mutation<User, { userId: string; role: UserRoleType }>({
      query: ({userId, role}) => ({
        url: `users/${userId}`,
        method: "PUT",
        data: {
          newRole: role,
        },
      }),
      transformResponse: defaultTransformFn,
      invalidatesTags: [{type: "Users"}],
    }),
    resetUserPassword: build.mutation<void, { email: string }>({
        query: ({email}) => ({
            url: `login/resetPassword`,
            method: "POST",
            data: {
                email: email
            }
        })
    })
  }),
  overrideExisting: false,
});

export const { useGetUsersQuery, useCreateUserMutation, useUpdateUserRoleMutation, useResetUserPasswordMutation } = usersApi;

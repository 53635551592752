import {
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiTextArea,
  EuiButton, EuiSpacer,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import { Business } from "api/entities/Business";
import { useNavigate } from "react-router-dom";
import { setBusinessBuilderAction } from "slices/NewOrderCreateSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "slices/RootReducer";
import {IsEmail, IsZipCode} from "../../utils/Validation";

export const NewOrderBusinessInfo: React.FC = () => {
  const { businessBuilder } = useSelector((state: RootState) => state.newOrder);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tempBusiness, setTempBusiness] = useState<Business>({
    name: "",
    address: {
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "usa",
    },
    phone: "",
    email: "",
    website: "",
    description: "",
  });

  useEffect(() => {
    if (businessBuilder) {
      setTempBusiness(businessBuilder);
    }
  }, [businessBuilder]);

  const onBusinessNameChange = (name: string) => {
    setTempBusiness({ ...tempBusiness, name });
  };

  const onBusinessEmailChange = (email: string) => {
    setTempBusiness({ ...tempBusiness, email });
  };

  const onBusinessPhoneChange = (phone: string) => {
    setTempBusiness({ ...tempBusiness, phone });
  };

  const onBusinessWebsiteChange = (website: string) => {
    setTempBusiness({ ...tempBusiness, website });
  };

  const onBusinessDescriptionChange = (description: string) => {
    setTempBusiness({ ...tempBusiness, description });
  };

  const onStreetChange = (street: string) => {
    setTempBusiness({
      ...tempBusiness,
      address: { ...tempBusiness.address, street },
    });
  };

  const onCityChange = (city: string) => {
    setTempBusiness({
      ...tempBusiness,
      address: { ...tempBusiness.address, city },
    });
  };

  const onStateChange = (state: string) => {
    setTempBusiness({
      ...tempBusiness,
      address: { ...tempBusiness.address, state },
    });
  };

  const onZipChange = (zip: string) => {
    setTempBusiness({
      ...tempBusiness,
      address: { ...tempBusiness.address, zip },
    });
  };

  const onCountryChange = (country: string) => {
    setTempBusiness({
      ...tempBusiness,
      address: { ...tempBusiness.address, country },
    });
  };

  const onNextClick = () => {
    dispatch(setBusinessBuilderAction(tempBusiness));
    navigate("/new/product");
  };

  const validFields = IsEmail(tempBusiness.email) && IsZipCode(tempBusiness.address.zip);

  const fieldsComplete =
      tempBusiness.name &&
      tempBusiness.email &&
      tempBusiness.phone &&
      tempBusiness.address.street &&
      tempBusiness.address.city &&
      tempBusiness.address.state &&
      tempBusiness.address.zip &&
      tempBusiness.address.country;

  const nextReady = validFields && fieldsComplete;

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem >
          <EuiFormRow label="Business Name">
            <EuiFieldText
                value={tempBusiness.name}
                onChange={(e) => onBusinessNameChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Description">
            <EuiTextArea
                value={tempBusiness.description}
                onChange={(e) => onBusinessDescriptionChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Client Email" isInvalid={!IsEmail(tempBusiness.email)} error={'Valid Email Required'}>
            <EuiFieldText
                value={tempBusiness.email}
                onChange={(e) => onBusinessEmailChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Client Phone">
            <EuiFieldText
                value={tempBusiness.phone}
                onChange={(e) => onBusinessPhoneChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Website">
            <EuiFieldText
                value={tempBusiness.website}
                onChange={(e) => onBusinessWebsiteChange(e.target.value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem >
          <EuiFormRow label="Street">
            <EuiFieldText
                value={tempBusiness.address.street}
                onChange={(e) => onStreetChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="City">
            <EuiFieldText
                value={tempBusiness.address.city}
                onChange={(e) => onCityChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="State">
            <EuiFieldText
                value={tempBusiness.address.state}
                onChange={(e) => onStateChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Zip" isInvalid={!IsZipCode(tempBusiness.address.zip)} error={'5 Digit Zip Code Required'}>
            <EuiFieldText
                value={tempBusiness.address.zip}
                onChange={(e) => onZipChange(e.target.value)}
            />
          </EuiFormRow>
          <EuiFormRow label="Country">
            <EuiFieldText
                value={tempBusiness.address.country}
                onChange={(e) => onCountryChange(e.target.value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer/>
      <EuiFormRow>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton fill disabled={!nextReady} onClick={onNextClick}>
              Continue
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFormRow>
    </>
  );
};

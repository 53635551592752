import React, {FC, useEffect, useState} from "react";
import {EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiTitle} from "@elastic/eui";
import {PaymentDetails} from "../../components/payments/PaymentDetails";
import {useNewOrderDetails} from "./useNewOrderDetails";
import {initializeBusinessAction} from "../../slices/NewOrderCreateSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../slices/RootReducer";
import {
    useAddPlanToBusinessMutation,
    useCreateBusinessMutation,
    useUpdatePaymentMethodMutation
} from "../../api/rtkQueryApi/BusinessesApi";
import {useNavigate} from "react-router-dom";
import {PaymentMethodDetails} from "../../components/payments/PaymentMethodDetails";
import {PaymentCard} from "../../api/entities/PaymentCard";
import moment from "moment/moment";
import {useCreatePaymentTokenMutation} from "../../api/rtkQueryApi/IntuitApi";
import {Business} from "../../api/entities/Business";

export const NewOrderPayment: FC = () => {
    const {businessBuilder} = useSelector((state: RootState) => state.newOrder);
    const orderDetails = useNewOrderDetails();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [createPaymentMethodToken, createPaymentMethodTokenRequest] = useCreatePaymentTokenMutation();
    const [updatePaymentMethod, updatePaymentMethodRequest] = useUpdatePaymentMethodMutation();
    const [addPlanToBusiness, addPlanToBusinessRequest] = useAddPlanToBusinessMutation();
    const [invalidCard, setInvalidCard] = useState(false);
    const [paymentMethodDetailsValid, setPaymentMethodDetailsValid] = useState(false);
    const [planName, setPlanName] = useState('');

    const [createBusiness, createBusinessRequest] = useCreateBusinessMutation();

    const [card, setCard] = useState<PaymentCard>({
        name: "",
        number: "",
        expMonth: moment().month(),
        expYear: moment().year(),
        cvc: "",
        address: {
            zip: "",
            country: "US",
            state: "AL",
            street: "",
            city: ""
        }
    });

    useEffect(() => {
        setPlanName(businessBuilder.product?.name ?? '');
    }, [businessBuilder]);

    const navigateToBusinessInfo = (businessId: string) => {
        dispatch(initializeBusinessAction());
        navigate(`/business/${businessId}`);
    }

    const initializePlan = (businessId: string) => {
        const productId = businessBuilder.productId;
        if (productId) {
            addPlanToBusiness({
                planName: planName,
                businessId: businessId,
                productId: productId,
                addonIds: businessBuilder.addonIds ?? [],
            }).then(() => {
                navigateToBusinessInfo(businessId);
            })
        }
    }

    const createPaymentMethod = (newBusiness?: Business, intuitToken?: string) => {
        if (newBusiness?.id && intuitToken) {
            const bizId = newBusiness.id;
            updatePaymentMethod({ businessId: newBusiness.id, intuitToken: intuitToken }).then(r => {
                if(r.error) {
                    navigateToBusinessInfo(bizId);
                }
                else {
                    initializePlan(bizId)
                }
            })
        }
    }

    const createNewBusiness = (intuitToken: string) => {
        createBusiness({
            business: {
                ...businessBuilder
            },
        }).then((response) => {
            const newBusiness = response.data;
            createPaymentMethod(newBusiness, intuitToken);
        })
    }

    const createIntuitToken = () => {
        createPaymentMethodToken({
            card: card
        }).then(r => {
            const intuitToken = r.data;
            if(!!intuitToken) {
                setInvalidCard(false);
                createNewBusiness(intuitToken);
            }
            else {
                setInvalidCard(true);
            }
        })
    }

    const onConfirmClick = () => {
        if (businessBuilder.productId) {
            createIntuitToken();
        }
    };

    const onBackClick = () => {
        navigate("/new/addons");
    }

    const isLoading = createBusinessRequest.isLoading || createPaymentMethodTokenRequest.isLoading || updatePaymentMethodRequest.isLoading || addPlanToBusinessRequest.isLoading;

    return (
        <>
            <EuiTitle size={"s"}>
                <h2>Confirm Purchase</h2>
            </EuiTitle>
            <EuiFormRow>
                <PaymentDetails orderDetails={orderDetails}/>
            </EuiFormRow>
            <EuiSpacer/>
            <EuiFormRow label={'Plan Name'}>
                <EuiFieldText placeholder={'Plan Name'} value={planName} onChange={e => setPlanName(e.target.value)}/>
            </EuiFormRow>
            <EuiFormRow isInvalid={invalidCard} error={"Card Invalid, please check info and resubmit"}>
                <PaymentMethodDetails card={card} onCardChange={setCard} onIsValidChange={setPaymentMethodDetailsValid}/>
            </EuiFormRow>
            <EuiSpacer/>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={onBackClick} fill disabled={isLoading}>Back</EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={onConfirmClick} fill isLoading={isLoading} isDisabled={!paymentMethodDetailsValid && planName.length > 0}>Launch</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}
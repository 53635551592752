import {EuiCard, EuiSpacer, EuiText} from "@elastic/eui";
import {ProductDetails} from "../../api/entities/Product";
import {FC} from "react";

export interface IProductDetailsCard {
    productDetails: ProductDetails;
}

export const ProductDetailsCard: FC<IProductDetailsCard> = (props) => {

    return (
        <EuiCard title={props.productDetails.name} description={props.productDetails.description} style={{width: 600}}>
            <EuiSpacer />
            <EuiText>${props.productDetails.pricePerMonth} / month for {props.productDetails.contractLengthMonths} months</EuiText>
        </EuiCard>
    )
}
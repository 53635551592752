import {EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiPanel} from "@elastic/eui";
import {MonthYearPicker} from "../time/MonthYearPicker";
import {StatePicker} from "../geography/StatePicker";
import React, {useEffect} from "react";
import {PaymentCard} from "../../api/entities/PaymentCard";
import {IsValidCreditCard, IsZipCode} from "../../utils/Validation";

export interface IPaymentMethodDetails {
    card: PaymentCard;
    onCardChange: (card: PaymentCard) => void;
    onIsValidChange?: (isValid: boolean) => void;
}

export const PaymentMethodDetails: React.FC<IPaymentMethodDetails> = props => {
    useEffect(() => {
        if(props.onIsValidChange) {
            let isValid = IsValidCreditCard(props.card.number) && props.card.name.length > 0 && props.card.cvc.length > 0 && IsZipCode(props.card.address.zip) && props.card.address.city.length > 0 && props.card.address.state.length > 0 && props.card.address.street.length > 0;
            props.onIsValidChange(isValid);
        }
    }, [props.onIsValidChange, props.card]);

    const onCardChange = (card: PaymentCard) => {
        props.onCardChange(card);
    }

    return (
        <EuiPanel hasBorder={true} hasShadow={false}>
            <EuiFormRow label={'Card Number'} isInvalid={!IsValidCreditCard(props.card.number)} error={'Invalid Card'}>
                <EuiFieldText value={props.card.number} onChange={(e) => onCardChange({...props.card, number: e.target.value})}/>
            </EuiFormRow>
            <EuiFormRow>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiFormRow label={'Name on Card'}>
                            <EuiFieldText value={props.card.name}
                                          onChange={(e) => onCardChange({...props.card, name: e.target.value})}/>
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow label={'CVC'}>
                            <EuiFieldText value={props.card.cvc}
                                          onChange={(e) => onCardChange({...props.card, cvc: e.target.value})}/>
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
            <EuiFormRow label={'Expiration Date'}>
                <MonthYearPicker month={Number(props.card.expMonth)} year={Number(props.card.expYear)}
                                 onChange={(y, m) => onCardChange({...props.card, expMonth: m, expYear: y})}/>
            </EuiFormRow>
            <EuiHorizontalRule size={'full'}/>
            <EuiFormRow label={'Street Address'}>
                <EuiFieldText value={props.card.address.street} onChange={(e) => onCardChange({
                    ...props.card,
                    address: {...props.card.address, street: e.target.value}
                })}/>
            </EuiFormRow>
            <EuiFormRow>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiFormRow label={'City'}>
                            <EuiFieldText value={props.card.address.city} onChange={(e) => onCardChange({
                                ...props.card,
                                address: {...props.card.address, city: e.target.value}
                            })}/>
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow label={'Postal Code'} isInvalid={!IsZipCode(props.card.address.zip)}>
                            <EuiFieldText value={props.card.address.zip} onChange={(e) => onCardChange({
                                ...props.card,
                                address: {...props.card.address, zip: e.target.value}
                            })}/>
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
            <EuiFormRow>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiFormRow label={'State'}>
                            <StatePicker onStateChange={e => onCardChange({...props.card, address: {...props.card.address, state: e}})}/>
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow label={'Country'}>
                            <EuiFieldText disabled={true} value={props.card.address.country} onChange={(e) => onCardChange({
                                ...props.card,
                                address: {...props.card.address, country: e.target.value}
                            })}/>
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </EuiPanel>
    )
}
import {BaseQueryFn} from "@reduxjs/toolkit/query";
import {BaseQueryFnProps} from "./AxiosBaseQuery";
import axios, {AxiosError} from "axios";
import {createApi} from "@reduxjs/toolkit/query/react";
import {PaymentCard} from "../entities/PaymentCard";

export const intuitBaseQuery =
    (): BaseQueryFn<BaseQueryFnProps, unknown, unknown, {}, {}> =>
        async ({url, method, data, params}) => {
            try {
                let axiosInstance = axios.create({
                    baseURL: `https://sandbox.api.intuit.com/`,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    }
                })

                const result = await axiosInstance.request({url, method, data, params})

                return {
                    data: result.data
                }
            } catch (axiosError) {
                const err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message
                    }
                }
            }
        }

export const intuitApi = createApi({
    reducerPath: 'intuitApi',
    baseQuery: intuitBaseQuery(),
    endpoints: b => ({
        createPaymentToken: b.mutation<string, { card: PaymentCard,  }>({
            query: ({card}) => {
                const uid = Math.random().toString(36).substring(20);
                const monthString = card.expMonth < 10 ? `0${card.expMonth}` : card.expMonth.toString();

                return {
                    url: `quickbooks/v4/payments/tokens`,
                    method: "POST",
                    headers: {
                        "request-Id": uid,
                        "Content-Type": "application/json"
                    },
                    data: {
                        card : {
                            name: card.name,
                            number: card.number,
                            expMonth: monthString,
                            address: {
                                postalCode: card.address.zip.toString(),
                                country: "US",
                                region: card.address.state,
                                streetAddress: card.address.street,
                                city: card.address.city
                            },
                            expYear: card.expYear.toString(),
                            cvc: card.cvc,
                            default: true
                        }
                    }
                };
            },
            transformResponse: (response: { value: string}) =>
            {
                return response.value
            }
        })
    })
})

export const {useCreatePaymentTokenMutation} = intuitApi;
import {
    EuiPanel,
    EuiSpacer,
    EuiText
} from "@elastic/eui";
import {INewOrderDetails} from "../../pages/newOrder/useNewOrderDetails";
import React from "react";

export interface IPaymentDetails {
    orderDetails: INewOrderDetails
}

export const PaymentDetails: React.FC<IPaymentDetails> = props => {
    return (
        <>
            <EuiSpacer/>
            <EuiPanel hasShadow={false} hasBorder={true}>
                <EuiText size={'xs'}>
                    <p>Product:
                        <p>
                            - {props.orderDetails.productDetails?.name} {(props.orderDetails.totalMonthlyPrice ?? 0) > 0 ? `|
                            ${props.orderDetails.productDetails?.pricePerMonth.toLocaleString()} / month` : ''}
                        </p>
                    </p>
                    <p>Addons:
                        <>
                            {props.orderDetails.addonLedger.map((addon, index) => {
                                return <p key={index}>- {addon.name}{addon.quantity > 1 ? ` x ${addon.quantity}` : ''} |
                                    ${addon.oneTimePrice.toLocaleString()} | ${addon.recurringPrice.toLocaleString()} /
                                    month</p>
                            })}
                        </>
                    </p>
                    <p>Total Now:
                        <p>${props.orderDetails.totalOneTimePrice.toLocaleString()}</p>
                    </p>
                    <p>Total Monthly:
                        <p>${props.orderDetails.totalMonthlyPrice?.toLocaleString()}</p>
                    </p>
                </EuiText>
            </EuiPanel>
        </>)
}
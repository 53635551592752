import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiTitle,
} from "@elastic/eui";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../slices/RootReducer";
import {setBusinessBuilderAction} from "../../slices/NewOrderCreateSlice";
import {useGetAddonsQuery} from "../../api/rtkQueryApi/AddonsApi";
import {Addon} from "../../api/entities/Addon";
import {AddonSelector} from "../addons/AddonSelector";
import {AddonsPanelViewer} from "../addons/AddonsPanelViewer";

export const NewOrderAddons: React.FC = () => {
    const {businessBuilder} = useSelector((state: RootState) => state.newOrder);
    const navigate = useNavigate();
    const getAddonsQuery = useGetAddonsQuery();
    const [selectedAddons, setSelectedAddons] = useState<Addon[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (businessBuilder.addonIds && getAddonsQuery.data) {
            const dataAddons = [...getAddonsQuery.data];
            const _addons : Addon[] = [];
            businessBuilder.addonIds.forEach(id => {
                const addon = dataAddons.find(addon => addon.id === id);
                if (addon) {
                    _addons.push(addon);
                }
            })
            setSelectedAddons(_addons);
        }
    }, [businessBuilder.addonIds, getAddonsQuery.data]);

    const onBackClick = () => {
        dispatch(setBusinessBuilderAction({...businessBuilder, addonIds: selectedAddons.map(a => a.id), addons: selectedAddons}));
        navigate("/new/product");
    };

    const onContinueToPaymentClick = () => {
        navigate("/new/payment");
        dispatch(setBusinessBuilderAction({...businessBuilder, addonIds: selectedAddons.map(a => a.id), addons: selectedAddons}));
    };


    const onAddonSelected = (addon: Addon) => {
        setSelectedAddons((prev) => {
            return [...prev, addon];
        })
    }

    const onAddonRemoved = (addon: Addon) => {
        setSelectedAddons((tAddons) => {
            const cAddons = [...tAddons]
            const index = cAddons.findIndex(a => a.id === addon.id);
            if (index > -1) {
                cAddons.splice(index, 1);
            }
            return [...cAddons];
        })
    }

    return (
        <>
            <EuiTitle size={"s"}>
                <h2>Available Addons</h2>
            </EuiTitle>
            <EuiSpacer/>
            <AddonSelector onAddonSelected={onAddonSelected} productGroupId={businessBuilder.productGroupId}/>
            <EuiSpacer/>
            <EuiTitle size={"s"}>
                <h2>Selected Addons</h2>
            </EuiTitle>
            <EuiSpacer/>
            <AddonsPanelViewer addons={selectedAddons} onAddonRemoveClick={onAddonRemoved} productGroupId={businessBuilder.productGroupId}/>
            <EuiSpacer/>
            <EuiFormRow>
                <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={onBackClick}>
                            Back
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={onContinueToPaymentClick}>
                            Continue to Payment
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </>
    );
};

import {Outlet, useNavigate, useParams} from "react-router-dom";
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageSection,
} from "@elastic/eui";
import {Business} from "../../api/entities/Business";
import {useEffect, useState} from "react";
import {
    useGetBusinessQuery,
} from "api/rtkQueryApi/BusinessesApi";

export const BusinessPage = () => {
    const {businessId} = useParams();
    const [business, setBusiness] = useState<Business>();
    const navigate = useNavigate();

    const getBusinessQuery = useGetBusinessQuery(
        {businessId: businessId ?? ""},
        {skip: !businessId},
    )
    useEffect(() => {
        if (getBusinessQuery.data) {
            setBusiness(getBusinessQuery.data);
        }
    }, [getBusinessQuery.data]);

    const onHeaderClick = () => {
        navigate(`/business/${businessId}`);
    }

    return (
        <EuiPage>
            <EuiPageBody>
                <EuiPageSection>
                    <EuiPageHeader pageTitle={business?.name} onClick={onHeaderClick}/>
                </EuiPageSection>
                <EuiPageSection>
                    <Outlet/>
                </EuiPageSection>
            </EuiPageBody>
        </EuiPage>
    );
};

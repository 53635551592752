import {EuiFormRow} from "@elastic/eui";
import {AddonPurchasePanel} from "../../components/addons/AddonPurchasePanel";
import {Addon} from "../../api/entities/Addon";

export interface IAddonsPanelViewer {
    addons: Addon[];
    onAddonRemoveClick?: (addon: Addon) => void;
    productGroupId?: string;
}

export const AddonsPanelViewer: React.FC<IAddonsPanelViewer> = props => {
    const addonGroups = props.addons.reduce((acc : Record<string, Addon[]>, a) => {
        if (!acc[a.id]) {
            acc[a.id] = [];
        }
        acc[a.id].push(a);
        return acc;
    }, {});

    const onAddonRemoved = (addon: Addon) => {
        props.onAddonRemoveClick?.(addon);
    }

    return (
        <>
            {Object.values(addonGroups).map((group) => {
                const addon = group[0];
                return (
                    <EuiFormRow>
                        <AddonPurchasePanel addonDetails={addon} quantity={group.length}
                                            onRemove={() => onAddonRemoved(addon)}
                                            productGroupId={props.productGroupId}/>
                    </EuiFormRow>
                );
            })}
        </>
    )
}
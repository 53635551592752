import {EuiPage, EuiPageHeader, EuiPageSection, EuiPageBody} from "@elastic/eui"
import {Outlet} from "react-router-dom";
import React from "react";

export const NewOrderPage: React.FC = () => {
    return (
        <EuiPage>
            <EuiPageBody>
                <EuiPageSection>
                    <EuiPageHeader pageTitle={"New Customer"}/>
                </EuiPageSection>
                <EuiPageSection>
                    <Outlet />
                </EuiPageSection>
            </EuiPageBody>
        </EuiPage>
    )
}
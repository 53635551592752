export interface Addon extends AddonDetails {
    id: string;
}

export interface AddonDetails {
    name: string;
    description: string;
    price: number;
    orderType: OrderType;
    specialPricingList: SpecialPricing[];
}

export enum OrderType {
    OneTime = 'OneTime',
    Recurring = 'Recurring',
}

export interface SpecialPricing {
    productGroupId: string,
    price: number,
}
import {createApi} from "@reduxjs/toolkit/query/react";
import {axiosBaseQuery} from "./AxiosBaseQuery";
import axios, {InternalAxiosRequestConfig} from "axios";
import config from "../../app/config";
import localStoreManagerService, {deleteAccessToken, getAccessToken} from "../../localStorage/LocalStoreManagerService";

export interface IBaseQuery {

}

export interface IBaseWithAccountQuery extends IBaseQuery {
    accountId: string
}

export function defaultTransformFn<T>(response: { data: T }) {
    return response.data
}

export const dsApi = createApi({
    reducerPath: 'dsApi',
    baseQuery: axiosBaseQuery(),
    endpoints: b => ({
    })
})

export let getCookie = function (name: string) {
    let nameEQ = name + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

const addAuthHeaders = (config: InternalAxiosRequestConfig) => {
    let accessToken = getAccessToken()
    if (accessToken) {
        config.headers!['Authorization'] = `Bearer ${accessToken}`
    }

    return Promise.resolve(config)
}

export interface ApiError {
    code: string
    name: string
    message: string
    description: string
}

export interface ApiResponse<T> {
    errors: ApiError[]
    data: T
    timestamp: string
}

export const publicAxios = () => {
    const instance = axios.create({
        baseURL: `${config.api.BASEURL}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })

    instance.interceptors.response.use(
        response => {
            return response
        },
        function (error) {
            return Promise.reject(error)
        }
    )

    return instance
}

export const securedContextAxios = () => {
    const instance = axios.create({
        baseURL: `${config.api.BASEURL}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    })

    instance.interceptors.request.use(addAuthHeaders)

    instance.interceptors.response.use(
        response => {
            return response
        },
        function (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                deleteAccessToken()
                localStoreManagerService.clearSessionStorage()
                // history.push('/login')
            }

            return Promise.reject(error)
        }
    )

    return instance
}
import React from "react";
import "./App.css";
import { EuiProvider } from "@elastic/eui";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/Store";
import { AuthenticationProvider } from "./authentication/AuthenticationProvider";
import {CreateRouter} from "./Routes/CreateRouter";

function App() {
  return (
    <EuiProvider colorMode={'light'}>
        <Provider store={store}>
          <AuthenticationProvider>
            <RouterProvider router={CreateRouter()} />
          </AuthenticationProvider>
        </Provider>
    </EuiProvider>
  );
}

export default App;

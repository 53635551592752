import { defaultTransformFn, dsApi } from "./DsApi";
import { Product } from "../entities/Product";
import {ProductGroup} from "../entities/ProductGroup";

const productsTags = dsApi.enhanceEndpoints({ addTagTypes: ["Products", "ProductGroups", "Product"] });

const productsApi = productsTags.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<Product[], void>({
      query: () => {
        return {
          url: `products`,
          method: "GET",
        };
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: "Products" }],
    }),
    getProductGroups: build.query<ProductGroup[], void>({
      query: () => {
        return {
          url: `products/groups`,
          method: "GET",
        };
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: "ProductGroups" }],
    }),
    getProduct: build.query<Product, { productId: string }>({
      query: ({ productId }) => {
        return {
          url: `products/${productId}`,
          method: "GET",
        };
      },
      transformResponse: defaultTransformFn,
      providesTags: () => [{ type: "Product" }],
    }),
  }),
  overrideExisting: false,
});

export const { useGetProductQuery, useGetProductsQuery, useGetProductGroupsQuery } = productsApi;

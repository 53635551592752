import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageSection,
} from "@elastic/eui";
import { Outlet } from "react-router-dom";
import React from "react";

export const UsersPage: React.FC = () => {
  return (
    <EuiPage>
      <EuiPageBody>
        <EuiPageSection>
          <EuiPageHeader pageTitle={"Users"} />
        </EuiPageSection>
        <EuiPageSection>
          <Outlet />
        </EuiPageSection>
      </EuiPageBody>
    </EuiPage>
  );
};

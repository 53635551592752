import {FC, useState} from "react";
import {Plan, PlanStatus} from "api/entities/Plan";
import {EuiBadge, EuiCard, EuiIcon, EuiLink, EuiSpacer, EuiText,} from "@elastic/eui";
import {PlanGetTotalRecurringPrice} from "../../utils/PlanUtils";
import moment from "moment";
import {NumberToCurrency} from "../../utils/Formatters";
import {GetAddonPrice} from "../../utils/ProductUtils";

export interface IPlanDetailsCard {
    plan: Plan
    onAddAddonClick?: (plan: Plan) => void
}

export const PlanDetailsCard: FC<IPlanDetailsCard> = props => {
    const [showAddonDetails, setShowAddonDetails] = useState(false)
    const productDetails = props.plan.productDetails
    const billingSchedule = props.plan.schedule
    const recurringPrice = PlanGetTotalRecurringPrice(props.plan)

    const onAddAddonClick = () => {
        if (props.onAddAddonClick) {
            props.onAddAddonClick(props.plan)
        }
    }

    const getBadgeColor = (status: PlanStatus) => {
        switch (status) {
            case PlanStatus.Active:
            case PlanStatus.Ending:
                return 'success'
            case PlanStatus.PaymentFailed:
                return 'danger'
            default:
                return 'default'
            }
    }

    return (
        <EuiCard title={props.plan.name} description={<EuiText size={'s'}><p><b>{productDetails.name}:</b> {productDetails.description}</p></EuiText>} textAlign={'left'}>
            <EuiBadge color={getBadgeColor(props.plan.status)}>{props.plan.status}</EuiBadge>
            <EuiSpacer size={'s'}/>
            <EuiText
                size={'xs'}>{billingSchedule.intervalsCompleted ?? 0} / {billingSchedule.totalIntervals} {billingSchedule.intervalUnit}s</EuiText>
            <EuiText size={'xs'}>{NumberToCurrency(recurringPrice)} / {billingSchedule.intervalUnit}</EuiText>
            <EuiText size={'xs'}>Start Date: {moment(billingSchedule.eventStartDate).format('MM/DD/yyyy')}</EuiText>
            <EuiText size={'xs'}>Next Event
                Date: {billingSchedule.nextEventDate ? moment(billingSchedule.nextEventDate).format('MM/DD/yyyy') : ''}</EuiText>
            <EuiSpacer size={'xs'}/>
            {showAddonDetails && (
                <>
                    <EuiSpacer size={'s'}/>
                    <EuiText><p><b>Addons</b> {props.onAddAddonClick && <EuiLink><EuiIcon type={"plusInSquare"} size={'s'} onClick={onAddAddonClick}/></EuiLink>}</p></EuiText>
                    {props.plan.addonDetailsList && props.plan.addonDetailsList.map(addon => (
                        <div style={{paddingLeft: 12}}>
                            <EuiText size={'s'}>{addon.name}</EuiText>
                            <EuiText size={'xs'}>
                                {NumberToCurrency(GetAddonPrice(addon, props.plan.productGroupId))}
                            </EuiText>
                        </div>
                    ))}
                    <EuiSpacer size={'s'}/>
                </>
            )}
            <EuiSpacer size={'xs'}/>
            <EuiLink onClick={() => setShowAddonDetails(!showAddonDetails)}>{showAddonDetails ? 'Hide' : 'Show'} Addons
                ({props.plan.addonDetailsList.length})</EuiLink>
        </EuiCard>
    )
}
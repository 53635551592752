import React, {FC, useState} from "react";
import {
    EuiButton,
    EuiModal,
    EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiText
} from "@elastic/eui";
import {PaymentCard} from "../../api/entities/PaymentCard";
import moment from "moment";
import {useCreatePaymentTokenMutation} from "../../api/rtkQueryApi/IntuitApi";
import {PaymentMethodDetails} from "./PaymentMethodDetails";
import {useUpdatePaymentMethodMutation} from "../../api/rtkQueryApi/BusinessesApi";

export interface IUpdatePaymentMethodModal {
    businessId: string;
    onClose: () => void;
    onPaymentMethodUpdated?: () => void;
}

export const UpdatePaymentMethodModal: FC<IUpdatePaymentMethodModal> = props => {
    const [isValidCard, setIsValidCard] = useState(false);
    const [card, setCard] = useState<PaymentCard>({
        name: "",
        number: "",
        expMonth: moment().month(),
        expYear: moment().year(),
        cvc: "",
        address: {
            zip: "",
            country: "US",
            state: "AL",
            street: "",
            city: ""
        }
    });
    const [createPaymentMethodToken, createPaymentMethodTokenQuery] = useCreatePaymentTokenMutation();
    const [updatePaymentMethod, updatePaymentMethodQuery] = useUpdatePaymentMethodMutation();
    const [cardTokenInvalid, setCardTokenInvalid] = useState(false);

    const onClose = () => {
        props.onClose();
    }

    const onConfirm = () => {
        createPaymentMethodToken({
            card: card
        }).then(r => {
            if (r.data)
            {
                updatePaymentMethod({ businessId: props.businessId, intuitToken: r.data }).then(() => {
                    props.onPaymentMethodUpdated?.();
                })
            }
            else
            {
                setCardTokenInvalid(true);
            }
        })
    }

    const isLoading = createPaymentMethodTokenQuery.isLoading || updatePaymentMethodQuery.isLoading;

    return (
        <EuiModal onClose={onClose} title={'Payment Information'} style={{padding: 12}}>
            <EuiModalHeader>
                <EuiModalHeaderTitle size={'s'}>
                    Add Payment Method
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                {cardTokenInvalid && <EuiText color={"warning"}><p>Invalid card information</p></EuiText>}
                <PaymentMethodDetails card={card} onCardChange={c => setCard(c)} onIsValidChange={setIsValidCard}/>
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={onConfirm} disabled={!isValidCard} fill isLoading={isLoading}>Add Card</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
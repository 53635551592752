import {Business} from "../../api/entities/Business";
import {
    EuiButton,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiLink,
    EuiSpacer, EuiText,
    EuiTextArea,
    EuiTitle
} from "@elastic/eui";
import {PlanDetailsCard} from "../../components/plans/PlanDetailsCard";
import {
    useGetBusinessPlansQuery,
    useGetBusinessQuery, useGetDefaultPaymentMethodQuery,
    useUpdateBusinessMutation
} from "../../api/rtkQueryApi/BusinessesApi";
import React, {FC, useEffect, useState} from "react";
import {Plan} from "../../api/entities/Plan";
import {useNavigate, useParams} from "react-router-dom";
import {PlansStatGroup} from "../../components/plans/PlansStatGroup";
import {AddAddonsToPlanModel} from "../addons/AddAddonsToPlanModel";
import {UpdatePaymentMethodModal} from "../../components/payments/UpdatePaymentMethodModal";
import {PaymentMethodCard} from "../../api/entities/PaymentMethodCard";
import {IsEmail, IsZipCode} from "../../utils/Validation";

export const BusinessSummary: FC = () => {
    const {businessId} = useParams();
    const getBusinessPlansQuery = useGetBusinessPlansQuery({businessId: businessId ?? ''}, {skip: !businessId});
    const [business, setBusiness] = useState<Business>();
    const [plans, setPlans] = useState<Plan[]>();
    const navigate = useNavigate();
    const [showAddAddonsModal, setShowAddAddonsModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<Plan>();
    const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = useState(false);
    const getDefaultPaymentMethodQuery = useGetDefaultPaymentMethodQuery({businessId: businessId ?? ''}, {skip: !businessId});
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCard>();

    const getBusinessQuery = useGetBusinessQuery(
        {businessId: businessId ?? ''}, {skip: !businessId}
    );

    const [updateBusiness, updateBusinessRequest] = useUpdateBusinessMutation();

    useEffect(() => {
        if (getBusinessPlansQuery.data) {
            setPlans(getBusinessPlansQuery.data);
        }
    }, [getBusinessPlansQuery.data]);

    useEffect(() => {
        if (getBusinessQuery.data) {
            setBusiness(getBusinessQuery.data);
        }
    }, [getBusinessQuery.data]);

    useEffect(() => {
        if (getDefaultPaymentMethodQuery.data?.last4) {
            setPaymentMethod(getDefaultPaymentMethodQuery.data);
        }
    }, [getDefaultPaymentMethodQuery.data])

    const onDescriptionChange = (description: string) => {
        if (business) {
            setBusiness({...business, description});
        }
    };

    const onEmailChange = (email: string) => {
        if (business) {
            setBusiness({...business, email});
        }
    };

    const onPhoneChange = (phone: string) => {
        if (business) {
            setBusiness({...business, phone});
        }
    };

    const onWebsiteChange = (website: string) => {
        if (business) {
            setBusiness({...business, website});
        }
    };

    const onCreativesUrlChange = (creativesUrl: string) => {
        if (business) {
            setBusiness({...business, creativesUrl});
        }
    }

    const onStreetChange = (street: string) => {
        if (business) {
            setBusiness({
                ...business,
                address: {...business.address, street},
            });
        }
    };

    const onCityChange = (city: string) => {
        if (business) {
            setBusiness({
                ...business,
                address: {...business.address, city},
            });
        }
    };

    const onStateChange = (state: string) => {
        if (business) {
            setBusiness({
                ...business,
                address: {...business.address, state},
            });
        }
    };

    const onZipChange = (zip: string) => {
        if (business) {
            setBusiness({
                ...business,
                address: {...business.address, zip},
            });
        }
    };

    const onSave = () => {
        if (business) {
            updateBusiness({id: business?.id ?? "", business: business});
        }
    };

    const onNewPlanClick = () => {
        navigate('addPlan')
    }

    const onAddAddonClick = (plan: Plan) => {
        setSelectedPlan(plan);
        setShowAddAddonsModal(true);
    }

    const onCloseAddAddonModal = () => {
        setShowAddAddonsModal(false);
        setSelectedPlan(undefined);
    }

    const isValid = IsEmail(business?.email ?? '') && IsZipCode(business?.address.zip ?? '');

    return (
        <>
            <PlansStatGroup plans={plans ?? []}/>
            <EuiSpacer/>
            {!getDefaultPaymentMethodQuery.isLoading && (
                <EuiFlexGroup>
                    {paymentMethod && (
                        <EuiFlexItem grow={false}>
                            <EuiText size={'s'}><p>Payment Method: xxxxxxxxxx{paymentMethod.last4}</p></EuiText>
                        </EuiFlexItem>
                    )}
                    <EuiFlexItem grow={false}>
                        <EuiLink onClick={() => {
                            setShowUpdatePaymentMethodModal(true)
                        }}><EuiText
                            size={'s'}>{paymentMethod ? 'Update Payment Method' : 'Add Payment Method'}</EuiText></EuiLink>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
            <EuiSpacer/>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <>
                        <EuiTitle size="s">
                            <h3>Details</h3>
                        </EuiTitle>
                        <EuiSpacer/>
                        <EuiFormRow label="Description">
                            <EuiTextArea
                                value={business?.description}
                                onChange={(e) => onDescriptionChange(e.target.value)}
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"Email"} isInvalid={!IsEmail(business?.email ?? '')} error={"Email is Invalid"}>
                            <EuiFieldText
                                value={business?.email}
                                onChange={(e) => onEmailChange(e.target.value)}
                                placeholder="Email"
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"Phone"}>
                            <EuiFieldText
                                value={business?.phone}
                                onChange={(e) => onPhoneChange(e.target.value)}
                                placeholder="Phone"
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"Website"}>
                            <EuiFieldText
                                value={business?.website}
                                onChange={(e) => onWebsiteChange(e.target.value)}
                                placeholder="Website"
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"Creatives Url"} labelAppend={<EuiLink href={business?.creativesUrl}
                                                                                  target={"_blank"}>Go</EuiLink>}>
                            <EuiFieldText
                                value={business?.creativesUrl}
                                onChange={(e) => onCreativesUrlChange(e.target.value)}
                                placeholder="Creatives Url"
                            />
                        </EuiFormRow>
                    </>
                </EuiFlexItem>
                <EuiFlexItem>
                    <>
                        <EuiTitle size="s">
                            <h3>Address</h3>
                        </EuiTitle>
                        <EuiSpacer/>
                        <EuiFormRow label={"Street"}>
                            <EuiFieldText
                                value={business?.address.street}
                                onChange={(e) => onStreetChange(e.target.value)}
                                placeholder="Street"
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"City"}>
                            <EuiFieldText
                                value={business?.address.city}
                                onChange={(e) => onCityChange(e.target.value)}
                                placeholder="City"
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"State"}>
                            <EuiFieldText
                                value={business?.address.state}
                                onChange={(e) => onStateChange(e.target.value)}
                                placeholder="State"
                            />
                        </EuiFormRow>
                        <EuiFormRow label={"Zip"} isInvalid={!IsZipCode(business?.address.zip ?? '')} error={'Invalid Zip Code'}>
                            <EuiFieldText
                                value={business?.address.zip}
                                onChange={(e) => onZipChange(e.target.value)}
                                placeholder="Zip"
                            />
                        </EuiFormRow>

                        <EuiFormRow>
                            <EuiButton onClick={onSave} isDisabled={!isValid} isLoading={updateBusinessRequest.isLoading}>Update</EuiButton>
                        </EuiFormRow>
                    </>
                </EuiFlexItem>
                <EuiFlexItem>
                    <>
                        <EuiTitle size="s">
                            <h3>Plans</h3>
                        </EuiTitle>
                        <EuiLink onClick={onNewPlanClick}>Add Plan</EuiLink>
                        <EuiSpacer/>
                        {plans?.map((plan, i) => (
                            <EuiFormRow key={i} fullWidth>
                                <PlanDetailsCard key={plan.id} plan={plan} onAddAddonClick={onAddAddonClick}/>
                            </EuiFormRow>
                        ))}
                    </>
                </EuiFlexItem>
            </EuiFlexGroup>
            {showAddAddonsModal && selectedPlan && (
                <AddAddonsToPlanModel plan={selectedPlan} onClose={onCloseAddAddonModal}/>
            )}
            {showUpdatePaymentMethodModal && businessId && (
                <UpdatePaymentMethodModal businessId={businessId} onClose={() => setShowUpdatePaymentMethodModal(false)}
                                          onPaymentMethodUpdated={() => setShowUpdatePaymentMethodModal(false)}/>
            )}
        </>
    )
}
import {EuiBasicTableColumn, EuiButton, EuiFlexGroup, EuiFlexItem, EuiInMemoryTable, EuiSpacer,} from "@elastic/eui";
import {User} from "../../api/entities/User";
import {useEffect, useState} from "react";
import {useGetUsersQuery} from "../../api/rtkQueryApi/UsersApi";
import {useNavigate} from "react-router-dom";
import {rolesService} from "../../localStorage/RolesService";
import {UserRoleType} from "../../authentication/UserRoleType";
import {UserUpdateModal} from "../../components/users/UserUpdateModal";

export const UsersGrid: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const usersGetQuery = useGetUsersQuery();
  const navigate = useNavigate();
  const isAdmin = rolesService.userInRoles([UserRoleType.Administrator, UserRoleType.GlobalAdministrator])

  const [updatingUser, setUpdatingUser] = useState<User>();
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    if (usersGetQuery.data) {
      setUsers(usersGetQuery.data);
    }
  }, [usersGetQuery.data]);

  const pagination = {
    initialPageSize: 5,
    pageSizeOptions: [3, 5, 8],
  };

  const onUpdateUserClick = (user: User) => {
    setUpdatingUser(user);
    setShowUpdateModal(true);
  }

  const columns: EuiBasicTableColumn<User>[] = [
    {
      name: "First Name",
      field: "firstName",
    },
    {
      name: "Last Name",
      field: "lastName",
    },
    {
      name: "Email",
      field: "email",
    },
    {
      name: "Role",
      field: "role",
    },
      ...(isAdmin ? [
        {
          name: "",
          field: "",
          render: (user: User) => {
            return <EuiButton size="s" onClick={() => onUpdateUserClick(user)}>Edit</EuiButton>
          }
        },
      ] : []),
  ];

  const onNewUserClick = () => {
    navigate("/users/new");
  };

  const onModalClose = () => {
    setShowUpdateModal(false);
    setUpdatingUser(undefined);
  }

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton onClick={onNewUserClick}>New User</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiInMemoryTable
        tableCaption="Demo of EuiInMemoryTable with uncontrolled selection"
        items={users}
        itemId="id"
        columns={columns}
        pagination={pagination}
        sorting={true}
      />
      {showUpdateModal && updatingUser && <UserUpdateModal user={updatingUser} onClose={onModalClose} onUserUpdated={onModalClose} />}
    </>
  );
};

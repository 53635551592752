import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit'
import {RootReducer, RootState} from "../slices/RootReducer";
import {dsApi} from "../api/rtkQueryApi/DsApi";
import {intuitApi} from "../api/rtkQueryApi/IntuitApi";

const store = configureStore({
    reducer: RootReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware().concat(dsApi.middleware).concat(intuitApi.middleware)
    }
})

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store

import {useNavigate, useParams} from "react-router-dom";
import {EuiButton, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiTitle} from "@elastic/eui";
import React, {useEffect, useState} from "react";
import {Product} from "../../api/entities/Product";
import {ProductDetailsCard} from "../../components/products/ProductDetailsCard";
import {AddonSelector} from "../addons/AddonSelector";
import {ProductSelector} from "../product/ProductSelector";
import {Addon} from "../../api/entities/Addon";
import {AddonsPanelViewer} from "../addons/AddonsPanelViewer";
import {ProductGroup} from "../../api/entities/ProductGroup";
import {useGetProductGroupsQuery} from "../../api/rtkQueryApi/ProductsApi";
import {GetTotalOneTimePrice} from "../../utils/PlanUtils";
import {NumberToCurrency} from "../../utils/Formatters";
import {useAddPlanToBusinessMutation, useGetDefaultPaymentMethodQuery} from "../../api/rtkQueryApi/BusinessesApi";
import {PaymentMethodCard} from "../../api/entities/PaymentMethodCard";
import {UpdatePaymentMethodModal} from "../../components/payments/UpdatePaymentMethodModal";

export const AddPlan: React.FC = () => {
    const {businessId} = useParams();
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const [selectedAddons, setSelectedAddons] = useState<Addon[]>([]);
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const getProductGroupsQuery = useGetProductGroupsQuery();
    const [selectedProductGroup, setSelectedProductGroup] = useState<ProductGroup>();
    const [showUpdatePaymentMethodModal, setShowUpdatePaymentMethodModal] = useState(false);
    const getDefaultPaymentMethodQuery = useGetDefaultPaymentMethodQuery({businessId: businessId ?? ''}, {skip: !businessId});
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethodCard>();
    const [planName, setPlanName] = useState('');

    const [addPlanToBusiness, addPlanToBusinessRequest] = useAddPlanToBusinessMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (getProductGroupsQuery.data) {
            setProductGroups(getProductGroupsQuery.data);
        }
    }, [getProductGroupsQuery.data]);

    useEffect(() => {
        if (getDefaultPaymentMethodQuery.data?.last4) {
            setPaymentMethod(getDefaultPaymentMethodQuery.data);
        }
    }, [getDefaultPaymentMethodQuery.data])

    const onProductSelected = (product: Product) => {
        setSelectedProduct(product);
        setSelectedProductGroup(productGroups.find(group => group.products.find(p => p.id === product.id)));
        setPlanName(product.name);
    }

    const onAddonSelected = (addon: Addon) => {
        setSelectedAddons((prev) => {
            return [...prev, addon];
        })
    }

    const onAddonRemoved = (addon: Addon) => {
        setSelectedAddons((tAddons) => {
            const cAddons = [...tAddons]
            const index = cAddons.findIndex(a => a.id === addon.id);
            if (index > -1) {
                cAddons.splice(index, 1);
            }
            return [...cAddons];
        })
    }

    const onPurchaseClick = () => {
        if(businessId && selectedProduct) {
            addPlanToBusiness({
                planName: planName,
                businessId: businessId,
                productId: selectedProduct?.id,
                addonIds: selectedAddons.map(a => a.id)
            }).then(() => {
                navigate(`/business/${businessId}`);
            })
        }
    }

    const totalCost = selectedProduct ? GetTotalOneTimePrice(selectedProduct, selectedAddons, selectedProductGroup?.id) : 0;

    return (
        <>
            {!paymentMethod ? (
                <EuiButton onClick={() => setShowUpdatePaymentMethodModal(true)}>Add Payment Method</EuiButton>
            ) : (
                <EuiButton fill={true} onClick={onPurchaseClick} disabled={!selectedProduct || !businessId} isLoading={addPlanToBusinessRequest.isLoading}>Purchase for {NumberToCurrency(totalCost)}</EuiButton>
            )}
            <EuiSpacer />
            <EuiFormRow label={'Plan Name'}>
                <EuiFieldText placeholder={'Plan Name'} value={planName} onChange={e => setPlanName(e.target.value)}/>
            </EuiFormRow>
            <EuiSpacer/>
            <EuiFlexGroup>
                {selectedProduct && (
                    <EuiFlexItem grow={false}>
                        <ProductDetailsCard productDetails={selectedProduct}/>
                    </EuiFlexItem>
                )}
                {selectedAddons.length > 0 && (
                    <EuiFlexItem grow={false}>
                        <AddonsPanelViewer addons={selectedAddons} onAddonRemoveClick={onAddonRemoved} productGroupId={selectedProductGroup?.id}/>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <span>
                        <EuiTitle size={"s"}>
                            <h2>Add Product</h2>
                        </EuiTitle>
                        <EuiSpacer size={"m"}/>
                        <ProductSelector selectedProductId={selectedProduct?.id} onProductSelected={onProductSelected}/>
                    </span>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <span>
                        <EuiTitle size={"s"}>
                            <h2>Addons</h2>
                        </EuiTitle>
                        <EuiSpacer size={"m"}/>
                        <AddonSelector onAddonSelected={onAddonSelected} productGroupId={selectedProductGroup?.id}/>
                    </span>
                </EuiFlexItem>
                <EuiFlexItem/>
            </EuiFlexGroup>

            {showUpdatePaymentMethodModal && businessId && (
                <UpdatePaymentMethodModal businessId={businessId} onClose={() => setShowUpdatePaymentMethodModal(false)}
                                          onPaymentMethodUpdated={() => setShowUpdatePaymentMethodModal(false)}/>
            )}
        </>
    );
}
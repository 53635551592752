import {defaultTransformFn, dsApi} from "./DsApi";
import {Business} from "../entities/Business";
import {Plan} from "../entities/Plan";
import {PaymentMethodCard} from "../entities/PaymentMethodCard";

const businessesTags = dsApi.enhanceEndpoints({
    addTagTypes: ["Businesses", "Business", "BusinessPlans", "BusinessPaymentMethod"],
});

const businessesApi = businessesTags.injectEndpoints({
    endpoints: (build) => ({
        getBusinesses: build.query<Business[], { salesId: string }>({
            query: () => {
                return {
                    url: `businesses`,
                    method: "GET",
                };
            },
            transformResponse: defaultTransformFn,
            providesTags: () => [{type: "Businesses"}],
        }),
        getBusiness: build.query<Business, { businessId: string }>({
            query: ({businessId}) => {
                return {
                    url: `businesses/${businessId}`,
                    method: "GET",
                };
            },
            transformResponse: defaultTransformFn,
            providesTags: (result, a, request) => [
                {type: "Business", id: request.businessId},
            ],
        }),
        createBusiness: build.mutation<Business, { business: Business }>({
            query: ({business}) => {
                return {
                    url: `businesses`,
                    method: "POST",
                    data: {
                        name: business.name,
                        address: business.address,
                        phone: business.phone,
                        email: business.email,
                        website: business.website,
                        description: business.description
                    }
                };
            },
            transformResponse: defaultTransformFn,
            invalidatesTags: [{type: "Businesses"}],
        }),
        updateBusiness: build.mutation<
            Business,
            { id: string; business: Business }
        >({
            query: ({id, business}) => {
                return {
                    url: `businesses/${id}`,
                    method: "PUT",
                    data: {
                        name: business.name,
                        address: business.address,
                        phone: business.phone,
                        email: business.email,
                        website: business.website,
                        description: business.description,
                        creativesUrl: business.creativesUrl,
                    },
                };
            },
            transformResponse: defaultTransformFn,
            invalidatesTags: (result, a, request) => [
                {type: "Businesses"},
                {type: "Business", id: request.id},
            ],
        }),
        getBusinessPlans: build.query<Plan[], { businessId: string }>({
            query: ({businessId}) => {
                return {
                    url: `businesses/${businessId}/plans`,
                    method: "GET",
                };
            },
            transformResponse: defaultTransformFn,
            providesTags: (result, a, request) => [
                {type: "BusinessPlans", id: request.businessId},
            ],
        }),
        addPlanToBusiness: build.mutation<Business, { planName: string, businessId: string; productId: string, addonIds: string[]}>({
            query: ({planName, businessId, productId, addonIds}) => {
                return {
                    url: `businesses/${businessId}/plans`,
                    method: "POST",
                    data: {
                        planName: planName,
                        productId: productId,
                        addonIds: addonIds,
                    },
                };
            },
            transformResponse: defaultTransformFn,
            invalidatesTags: (result, a, request) => [
                {type: "BusinessPlans", id: request.businessId},
            ],
        }),
        addAddonsToPlan: build.mutation<Business, { businessId: string; planId: string; addonIds: string[] }>({
            query: ({businessId, planId, addonIds}) => {
                return {
                    url: `businesses/${businessId}/plans/${planId}/addAddons`,
                    method: "POST",
                    data: {
                        addonIds: addonIds,
                    }
                };
            },
            transformResponse: defaultTransformFn,
            invalidatesTags: (result, a, request) => [
                {type: "BusinessPlans", id: request.businessId},
            ],
        }),
        updatePaymentMethod: build.mutation<Business, { businessId: string, intuitToken: string }>({
            query: ({businessId, intuitToken}) => {
                return {
                    url: `businesses/${businessId}/updatePaymentMethod`,
                    method: "POST",
                    data: {
                        paymentMethodToken: intuitToken,
                    },
                };
            },
            transformResponse: defaultTransformFn,
            invalidatesTags: (result, a, request) => [
                {type: "Business", id: request.businessId},
                {type: "BusinessPaymentMethod", id: request.businessId},
            ],
        }),
        getDefaultPaymentMethod: build.query<PaymentMethodCard, { businessId: string }>({
            query: ({businessId}) => {
                return {
                    url: `businesses/${businessId}/paymentMethod`,
                    method: "GET",
                };
            },
            transformResponse: defaultTransformFn,
            providesTags: (result, a, request) => [
                {type: "BusinessPaymentMethod", id: request.businessId},
            ],
        })
    }),
    overrideExisting: false,
});

export const {
    useGetBusinessesQuery,
    useGetBusinessQuery,
    useUpdateBusinessMutation,
    useCreateBusinessMutation,
    useGetBusinessPlansQuery,
    useAddPlanToBusinessMutation,
    useAddAddonsToPlanMutation,
    useUpdatePaymentMethodMutation,
    useGetDefaultPaymentMethodQuery
} = businessesApi;

import {FC, useState} from "react";
import {useResetUserPasswordMutation, useUpdateUserRoleMutation} from "api/rtkQueryApi/UsersApi";
import {EuiFormRow, EuiConfirmModal, EuiOverlayMask, EuiSelect, EuiLink, EuiText, EuiProgress, EuiCallOut} from "@elastic/eui";
import {User} from "api/entities/User";
import {UserRoleType} from "authentication/UserRoleType";

export interface IUserUpdateModal {
    onUserUpdated?: () => void;
    onClose: () => void;
    user: User;
}

export const UserUpdateModal : FC<IUserUpdateModal> = props => {
    const [selectedRole, setSelectedRole] = useState<UserRoleType>(props.user.role);
    const [updateUser, updateUserRequest] = useUpdateUserRoleMutation();
    const [resetPassword, resetPasswordRequest] = useResetUserPasswordMutation();
    const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);

    const roleOptions = [
        { value: UserRoleType.Administrator, text: "Administrator" },
        { value: UserRoleType.AssociateCoach, text: "Associate Coach" },
        { value: UserRoleType.Creative, text: 'Creative'}
    ]

    const onUpdateUserClick = () => {
        updateUser({userId: props.user.id, role: selectedRole}).finally(() => {
            props.onUserUpdated?.();
        })
    };

    const onResetPasswordClick = () => {
        resetPassword({email: props.user.email}).then(() => {
            setShowPasswordResetMessage(true);
            setTimeout(() => {
                setShowPasswordResetMessage(false);
            }, 5000);
        })
    }

    return (
        <EuiOverlayMask>
            <EuiConfirmModal title={"Update User"} onCancel={props.onClose} onConfirm={onUpdateUserClick} isLoading={updateUserRequest.isLoading} confirmButtonText={'Update'}>
                <EuiFormRow label={"User Role"}>
                    <EuiSelect options={roleOptions} value={selectedRole} onChange={(e) => setSelectedRole(e.target.value as UserRoleType)} />
                </EuiFormRow>
                <EuiFormRow>
                    {
                        resetPasswordRequest.isLoading ? <EuiProgress size={'xs'} /> :
                            <EuiLink onClick={onResetPasswordClick}><EuiText size={'s'}>Reset Password</EuiText></EuiLink>
                    }
                </EuiFormRow>
                {
                    showPasswordResetMessage && <EuiFormRow><EuiCallOut size={'s'} title={`Password reset email sent`} color="success" iconType="user" /></EuiFormRow>
                }
            </EuiConfirmModal>
        </EuiOverlayMask>
    );
}
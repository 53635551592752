import React, {FC, useState} from "react";
import {useResetUserPasswordMutation} from "../../api/rtkQueryApi/UsersApi";
import {EuiCallOut, EuiConfirmModal, EuiFieldText, EuiFormRow, EuiOverlayMask} from "@elastic/eui";

export interface IForgotPasswordModal {
    onCancel: () => void;
    onPasswordReset?: () => void;
}

export const ForgotPasswordModal: FC<IForgotPasswordModal> = props => {
    const [resetPassword, resetPasswordRequest] = useResetUserPasswordMutation();
    const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);
    const [email, setEmail] = useState<string>("");

    const onResetPasswordClick = () => {
        resetPassword({email: email}).then(() => {
            setShowPasswordResetMessage(true);
            setTimeout(() => {
                setShowPasswordResetMessage(false);
                props.onPasswordReset?.();
            }, 5000);
        })
    }

    return (
        <EuiOverlayMask>
            <EuiConfirmModal title={"Reset Password"} onCancel={props.onCancel} onConfirm={onResetPasswordClick} isLoading={resetPasswordRequest.isLoading} confirmButtonText={'Reset'} cancelButtonText={'Cancel'}>
                <EuiFormRow label={"Email"}>
                    <EuiFieldText value={email} onChange={(e) => setEmail(e.target.value)} />
                </EuiFormRow>
                {
                    showPasswordResetMessage && <EuiFormRow><EuiCallOut size={'s'} title={`Password reset email sent`} color="success" iconType="user" /></EuiFormRow>
                }
            </EuiConfirmModal>
        </EuiOverlayMask>
    );
}
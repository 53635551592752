export const ACCESS_TOKEN = 'accessToken'
export const USER_ROLES = 'roles'

export class LocalStoreManagerService {
    private reservedKeys: string[] = ['sync_keys', 'addToSyncKeys', 'removeFromSyncKeys', 'getSessionStorage', 'setSessionStorage', 'addToSessionStorage', 'removeFromSessionStorage', 'clearAllSessionsStorage']

    public clearSessionStorage() {
        sessionStorage.clear()
    }

    public saveSessionData(key: string, data: any) {
        this.testForInvalidKeys(key)

        localStorage.removeItem(key)
        this.sessionStorageSetItem(key, data)
    }

    public savePermanentData(key: string, data: any) {
        this.testForInvalidKeys(key)

        sessionStorage.removeItem(key)
        this.localStorageSetItem(key, data)
    }

    public exists(key: string) {
        let data = sessionStorage.getItem(key)

        if (data == null) {
            data = localStorage.getItem(key)
        }

        return data != null
    }

    public getData(key: string) {
        this.testForInvalidKeys(key)

        let data = this.sessionStorageGetItem(key)

        if (data == null) {
            data = this.localStorageGetItem(key)
        }

        return data
    }

    public getDataObject<T>(key: string, isDateType = false): T | null {
        let data = this.getData(key)

        if (data != null) {
            if (isDateType) {
                data = new Date(data)
            }
            return data as T
        }
        return null
    }

    public deleteData(key: string) {
        this.testForInvalidKeys(key)

        sessionStorage.removeItem(key)
        localStorage.removeItem(key)
    }

    private testForInvalidKeys(key: string) {
        if (!key) {
            throw new Error('key cannot be empty')
        }

        if (this.reservedKeys.some(x => x === key)) {
            throw new Error(`The storage key "${key}" is reserved and cannot be used. Please use a different key`)
        }
    }

    private localStorageSetItem(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data))
    }

    private sessionStorageSetItem(key: string, data: any) {
        sessionStorage.setItem(key, JSON.stringify(data))
    }

    private localStorageGetItem(key: string) {
        return jsonTryParse(localStorage.getItem(key))
    }

    private sessionStorageGetItem(key: string) {
        return jsonTryParse(sessionStorage.getItem(key))
    }
}

export function jsonTryParse(value: string | null | undefined) {
    try {
        return value && JSON.parse(value)
    } catch (e) {
        if (value === 'undefined') {
            // eslint-disable-next-line no-void
            return void 0
        }
        return value
    }
}

const localStoreManagerService = new LocalStoreManagerService()
export default localStoreManagerService

export const clearSessionStorage = () => localStoreManagerService.clearSessionStorage()

export const getAccessToken = () => localStoreManagerService.getDataObject<string>(ACCESS_TOKEN)
export const setAccessToken = (value: string) => localStoreManagerService.savePermanentData(ACCESS_TOKEN, value)
export const deleteAccessToken = () => localStoreManagerService.deleteData(ACCESS_TOKEN)

export const getUserRoles = () => localStoreManagerService.getDataObject<string[]>(USER_ROLES)
export const setUserRoles = (value: string) => localStoreManagerService.savePermanentData(USER_ROLES, value)
export const deleteUserRoles = () => localStoreManagerService.deleteData(USER_ROLES)
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiTitle,
} from "@elastic/eui";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../slices/RootReducer";
import {useGetProductGroupsQuery} from "../../api/rtkQueryApi/ProductsApi";
import {Product} from "../../api/entities/Product";
import {ProductGroup} from "../../api/entities/ProductGroup";
import {setBusinessBuilderAction} from "../../slices/NewOrderCreateSlice";
import {ProductDetailsCard} from "../../components/products/ProductDetailsCard";
import {ProductSelector} from "../product/ProductSelector";

export const NewOrderProduct: React.FC = () => {
    const {businessBuilder} = useSelector((state: RootState) => state.newOrder);
    const navigate = useNavigate();
    const getProductGroupsQuery = useGetProductGroupsQuery();
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product>();
    const dispatch = useDispatch();

    useEffect(() => {
        if (getProductGroupsQuery.data) {
            setProductGroups(getProductGroupsQuery.data);
            if (businessBuilder.productId) {
                setSelectedProduct(getProductGroupsQuery.data.flatMap(group => group.products).find(product => product.id === businessBuilder.productId));
            }
        }
    }, [getProductGroupsQuery.data, businessBuilder.productId]);

    const onBackClick = () => {
        const selectedProductGroup = productGroups.find(group => group.products.find(product => product.id === selectedProduct?.id));
        dispatch(setBusinessBuilderAction({...businessBuilder, productId: selectedProduct?.id, product: selectedProduct, productGroupId: selectedProductGroup?.id}));
        navigate("/new");
    };

    const onContinueClick = () => {
        const selectedProductGroup = productGroups.find(group => group.products.find(product => product.id === selectedProduct?.id));
        dispatch(setBusinessBuilderAction({...businessBuilder, productId: selectedProduct?.id, product: selectedProduct, productGroupId: selectedProductGroup?.id}));
        navigate("/new/addons");
    };

    const onProductSelected = (product: Product) => {
        setSelectedProduct(product);
    }

    return (
        <>

            <EuiFlexGroup>
                {selectedProduct && (
                    <EuiFlexItem grow={false}>
                        <ProductDetailsCard productDetails={selectedProduct}/>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
            <EuiSpacer/>
            <EuiTitle size={"s"}>
                <h2>Available Products</h2>
            </EuiTitle>
            <EuiSpacer size={"m"}/>
            <ProductSelector selectedProductId={selectedProduct?.id} onProductSelected={onProductSelected} center={true}/>
            <EuiSpacer size={'m'}/>
            <EuiFormRow>
                <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={onBackClick}>
                            Back
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill onClick={onContinueClick} disabled={!selectedProduct?.id}>
                            Continue
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </>
    );
};

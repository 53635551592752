import { defaultTransformFn, dsApi } from "./DsApi";
import {Addon} from "../entities/Addon";

const addonsTags = dsApi.enhanceEndpoints({ addTagTypes: ["Addons", "Addon"] });

const addonsApi = addonsTags.injectEndpoints({
    endpoints: (build) => ({
        getAddons: build.query<Addon[], void>({
            query: () => {
                return {
                    url: `addons`,
                    method: "GET",
                };
            },
            transformResponse: defaultTransformFn,
            providesTags: () => [{ type: "Addons" }],
        }),
        getAddon: build.query<Addon, { addonId: string }>({
            query: request => {
                return {
                    url: `addons/${request.addonId}`,
                    method: "GET",
                };
            },
            transformResponse: defaultTransformFn,
            providesTags: (response, _, request) => [{ type: "Addon", id: request.addonId }],
        }),
        createAddon: build.mutation<Addon, { addon: Addon }>({
            query: request => {
                return {
                    url: `addons`,
                    method: "POST",
                    data: request.addon,
                };
            },
            transformResponse: defaultTransformFn,
            invalidatesTags: [{ type: "Addons" }],
        })
    }),
    overrideExisting: false,
});

export const { useGetAddonQuery, useGetAddonsQuery, useCreateAddonMutation } = addonsApi;

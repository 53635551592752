import React, {FC} from "react";
import {EuiFlexGroup, EuiFlexItem, EuiSelect} from "@elastic/eui";
import {EuiSelectOption} from "@elastic/eui/src/components/form/select/select";

export interface IMonthYearPicker {
    year: number;
    month: number;
    onChange: (year: number, month: number) => void;
    maxYear?: number;
    minYear?: number;
}

export const MonthYearPicker: FC<IMonthYearPicker> = (props) => {
    const months : EuiSelectOption[] = [
        {value: 1, text: "January"},
        {value: 2, text: "February"},
        {value: 3, text: "March"},
        {value: 4, text: "April"},
        {value: 5, text: "May"},
        {value: 6, text: "June"},
        {value: 7, text: "July"},
        {value: 8, text: "August"},
        {value: 9, text: "September"},
        {value: 10, text: "October"},
        {value: 11, text: "November"},
        {value: 12, text: "December"}
    ]

    const years : EuiSelectOption[] = [];
    for (let i = (props.minYear || 2021); i <= (props.maxYear || 2035); i++) {
        years.push({value: i, text: i.toString()});
    }


    const onMonthChange = (month: number) => {
        props.onChange(props.year, month);
    }

    const onYearChange = (year: number) => {
        props.onChange(year, props.month);
    }

    return (
        <EuiFlexGroup>
            <EuiFlexItem>
                <EuiSelect
                    options={months}
                    value={props.month}
                    onChange={(e) => onMonthChange(Number(e.target.value))}
                    aria-label="Select month"
                />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiSelect
                    options={years}
                    value={props.year}
                    onChange={(e) => onYearChange(Number(e.target.value))}
                    aria-label="Select year"
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}
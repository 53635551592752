import {EuiFlexGroup, EuiFlexItem} from "@elastic/eui";
import {AddonDetailsCard} from "../../components/addons/AddonDetailsCard";
import {useEffect, useState} from "react";
import {useGetAddonsQuery} from "../../api/rtkQueryApi/AddonsApi";
import {Addon} from "../../api/entities/Addon";

export interface IAddonSelector {
    productGroupId?: string;
    onAddonSelected?: (addon: Addon) => void;
}

export const AddonSelector: React.FC<IAddonSelector> = props => {
    const getAddonsQuery = useGetAddonsQuery();
    const [addons, setAddons] = useState<Addon[]>([]);

    useEffect(() => {
        if (getAddonsQuery.data) {
            setAddons(getAddonsQuery.data);
        }
    }, [getAddonsQuery.data]);

    const onAddonSelected = (addon: Addon) => {
        props.onAddonSelected?.(addon);
    }

    return (
        <>
            <EuiFlexGroup wrap justifyContent={'center'} alignItems={'center'}>
                {addons.map((addon) => {
                    return (
                        <EuiFlexItem grow={false} style={{alignSelf: "stretch"}}>
                            <AddonDetailsCard addonDetails={addon} onAddonSelected={() => onAddonSelected(addon)} productGroupId={props.productGroupId}/>
                        </EuiFlexItem>
                    );
                })}
            </EuiFlexGroup>
        </>
    )
}
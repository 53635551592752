const dev = {
  api: {
    BASEURL: "https://localhost:5040/",
  },
};

const stage = {
  api: {
    BASEURL: "https://localhost:5040/",
  },
};

const uat = {
  api: {
    BASEURL: "https://localhost:5040/",
  },
};

const prod = {
  api: {
    BASEURL: "https://dragonslayer-api.adcritter.com/",
  },
};

let config;

switch (process.env.REACT_APP_ENV) {
  case "UAT":
    config = uat;
    break;

  case "Staging":
    config = stage;
    break;

  case "Development":
    config = dev;
    break;

  default:
    config = prod;
}

const defaultConfig = {
  graph: {
    BASEURL: "https://graph.microsoft.com/v1.0/",
  },
  TIMEOUT: 1500,
  GRAPH_SCOPES: ["openid", "profile", "User.Read", "GroupMember.Read.All"],
  googleMaps: {
    APIKEY: "AIzaSyBJ-T89Z2B1MH6p4sk_zScWx1mtcWuv3wI",
  },
  ...config,
};

export default defaultConfig;

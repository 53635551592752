import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Business } from "api/entities/Business";

export interface BusinessBuilder extends Business {
    productId?: string;
    addonIds?: string[];
    productGroupId?: string;
}

export const NewOrderCreateSlice = createSlice({
  name: "newOrderCreate",
  initialState: {
    businessBuilder: {
      name: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip: "",
        country: "usa",
      },
      phone: "",
      email: "",
      website: "",
      description: "",
    } as BusinessBuilder,
  },
  reducers: {
    initializeBusinessAction: (state) => {
        state.businessBuilder = {
            name: "",
            address: {
            street: "",
            city: "",
            state: "",
            zip: "",
            country: "usa",
            },
            phone: "",
            email: "",
            website: "",
            description: "",
        };
    },
    setBusinessBuilderAction: (state, action: PayloadAction<BusinessBuilder>) => {
      state.businessBuilder = action.payload;
    },
  },
});

export const { initializeBusinessAction, setBusinessBuilderAction } = NewOrderCreateSlice.actions;

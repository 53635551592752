import {createBrowserRouter} from "react-router-dom";
import {Root} from "../layout/Root";
import React from "react";
import {BusinessesPage} from "../pages/business/BusinessesPage";
import {NewOrderPage} from "../pages/newOrder/NewOrderPage";
import {NewOrderBusinessInfo} from "../pages/newOrder/NewOrderBusinessInfo";
import {NewOrderProduct} from "../pages/newOrder/NewOrderProduct";
import {BusinessPage} from "../pages/business/BusinessPage";
import {UsersPage} from "../pages/users/UsersPage";
import {UsersGrid} from "../pages/users/UsersGrid";
import {NewUser} from "../pages/users/NewUser";
import {NotFound} from "./NotFound";
import {NewOrderAddons} from "../pages/newOrder/NewOrderAddons";
import {BusinessSummary} from "../pages/business/BusinessSummary";
import {AddPlan} from "../pages/business/AddPlan";
import {NewOrderPayment} from "../pages/newOrder/NewOrderPayment";

export const CreateRouter = () => {
    return createBrowserRouter([
        {
            path: "/",
            element: <Root/>,
            errorElement: <NotFound/>,
            children: [
                {
                    path: "/",
                    element: <BusinessesPage/>,
                },
                {
                    path: "new",
                    element: <NewOrderPage/>,
                    children: [
                        {
                            path: "",
                            element: <NewOrderBusinessInfo/>,
                        },
                        {
                            path: "product",
                            element: <NewOrderProduct/>,
                        },
                        {
                            path: "addons",
                            element: <NewOrderAddons/>,
                        },
                        {
                            path: "payment",
                            element: <NewOrderPayment/>,
                        },
                    ],
                },
                {
                    path: "users",
                    element: <UsersPage/>,
                    children: [
                        {
                            path: "",
                            element: <UsersGrid/>,
                        },
                        {
                            path: "new",
                            element: <NewUser/>,
                        },
                    ],
                },
                {
                    path: "business/:businessId",
                    element: <BusinessPage/>,
                    children: [
                        {
                            path: "",
                            element: <BusinessSummary/>,
                        },
                        {
                            path: "addPlan",
                            element: <AddPlan/>,
                        }
                    ]
                },
            ],
        },
    ]);
};

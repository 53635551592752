import {EuiButton, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiSpacer, EuiModalFooter} from "@elastic/eui";
import {Plan} from "../../api/entities/Plan";
import {AddonSelector} from "./AddonSelector";
import {Addon} from "../../api/entities/Addon";
import {useState} from "react";
import {AddonsPanelViewer} from "./AddonsPanelViewer";
import {useAddAddonsToPlanMutation} from "../../api/rtkQueryApi/BusinessesApi";

export interface IAddAddonModel {
    plan: Plan
    onClose?: () => void
}

export const AddAddonsToPlanModel: React.FC<IAddAddonModel> = (props) => {
    const [selectedAddons, setSelectedAddons] = useState<Addon[]>([])
    const [addAddonsToPlan, addAddonsToPlanRequest] = useAddAddonsToPlanMutation();

    const onClose = () => {
        props.onClose?.()
    }

    const onAddonSelected = (addon: Addon) => {
        setSelectedAddons((prev) => {
            return [...prev, addon];
        })
    }

    const onAddonRemoved = (addon: Addon) => {
        setSelectedAddons((tAddons) => {
            const cAddons = [...tAddons]
            const index = cAddons.findIndex(a => a.id === addon.id);
            if (index > -1) {
                cAddons.splice(index, 1);
            }
            return [...cAddons];
        })
    }

    const onFinalizeAndPayClick = () => {
        addAddonsToPlan({
            businessId: props.plan.businessId,
            planId: props.plan.id,
            addonIds: selectedAddons.map(a => a.id)
        }).then(() => {
            onClose()
        })
    }

    return (
        <EuiModal onClose={onClose}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    Add Addons to {props.plan.productDetails.name}
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                Select The Addons You Want To Add:
                <EuiSpacer/>
                <AddonSelector productGroupId={props.plan.productGroupId} onAddonSelected={onAddonSelected}/>

                {selectedAddons.length > 0 && (
                    <>
                        <EuiSpacer/>
                        Selected Addons:
                        <EuiSpacer/>
                        <AddonsPanelViewer addons={selectedAddons} onAddonRemoveClick={onAddonRemoved} productGroupId={props.plan.productGroupId}/>
                    </>
                )}
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={onFinalizeAndPayClick} fill isLoading={addAddonsToPlanRequest.isLoading} disabled={selectedAddons.length <= 0}>Finalize and Pay</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
import {
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiFieldPassword,
  EuiButton,
} from "@elastic/eui";
import { useState } from "react";
import { useCreateUserMutation } from "../../api/rtkQueryApi/UsersApi";

export interface IUserCreatePanel {
  onUserCreated?: () => void;
}

export const UserCreatePanel: React.FC<IUserCreatePanel> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [createUser, createUserRequest] = useCreateUserMutation();

  const onCreateUserClick = () => {
    createUser({ email, firstName, lastName, password }).finally(() => {
      props.onUserCreated?.();
    });
  };

  const isValidPassword = (password: string) => {
    return true;
  };

  return (
    <EuiPanel>
      <>
        <EuiFormRow label={"Email"}>
          <EuiFieldText
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label={"Password"}>
          <EuiFieldPassword
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </EuiFormRow>
        <EuiFormRow label={"First Name"}>
          <EuiFieldText
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </EuiFormRow>
        <EuiFormRow label={"Last Name"}>
          <EuiFieldText
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </EuiFormRow>
        <EuiFormRow>
          <EuiButton
            isDisabled={
              !email || !isValidPassword(password) || !firstName || !lastName
            }
            onClick={onCreateUserClick}
            isLoading={createUserRequest.isLoading}
          >
            Create User
          </EuiButton>
        </EuiFormRow>
      </>
    </EuiPanel>
  );
};

import {getUserRoles} from "./LocalStoreManagerService";
import {UserRoleType} from "../authentication/UserRoleType";

class RolesService {
    public userHasRole(role: UserRoleType): boolean {
        const roles = getUserRoles()
        if(!roles)
        {
            return false
        }
        return roles.includes(role)
    }

    public userInRoles(roles: UserRoleType[]): boolean {
        const userRoles = getUserRoles()
        if(!userRoles)
        {
            return false
        }
        return roles.some(r => userRoles.includes(r))
    }
}

export const rolesService = new RolesService()
import {EuiFlexGroup, EuiFlexItem} from "@elastic/eui";
import {ProductGroupCard} from "../../components/products/ProductGroupCard";
import {useGetProductGroupsQuery} from "../../api/rtkQueryApi/ProductsApi";
import {useEffect, useState} from "react";
import {ProductGroup} from "../../api/entities/ProductGroup";
import {Product} from "../../api/entities/Product";

export interface IProductSelector {
    selectedProductId?: string;
    onProductSelected?: (product: Product) => void;
    center?: boolean;
}

export const ProductSelector: React.FC<IProductSelector> = props => {
    const getProductGroupsQuery = useGetProductGroupsQuery();
    const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);

    useEffect(() => {
        if (getProductGroupsQuery.data) {
            setProductGroups(getProductGroupsQuery.data);
        }
    }, [getProductGroupsQuery.data]);

    const onProductSelected = (product: Product) => {
        props.onProductSelected?.(product);
    }

    return (
        <EuiFlexGroup wrap justifyContent={props.center ? 'center' : undefined} alignItems={props.center ? 'center' : undefined}>
            {productGroups.map((group) => {
                return (
                    <EuiFlexItem grow={false} style={{alignSelf: "stretch"}}>
                        <ProductGroupCard productGroup={group} onProductSelected={onProductSelected} selectedProductId={props.selectedProductId}/>
                    </EuiFlexItem>
                );
            })}
        </EuiFlexGroup>
    );
}
export const IsEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const IsZipCode = (zip: string) => {
    return /^\d{5}$/.test(zip);
}

export const IsValidCreditCard = (cardNumber: string) => {
    // check all characters are digits and the length is between 13 and 19
    return /^\d{13,19}$/.test(cardNumber)
}
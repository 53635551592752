import {
    EuiPage,
    EuiPageHeader,
    EuiPageSection,
    EuiInMemoryTable,
    EuiPageBody,
    EuiLink,
    EuiFieldSearch,
    EuiButton, EuiSpacer, EuiFormRow, EuiBasicTableColumn
} from "@elastic/eui"
import {useEffect, useState} from "react";
import {Business} from "api/entities/Business";
import {useNavigate} from "react-router-dom";
import {useGetBusinessesQuery} from "../../api/rtkQueryApi/BusinessesApi";
import moment, {} from "moment";
import {useGetUsersQuery} from "../../api/rtkQueryApi/UsersApi";
import {rolesService} from "../../localStorage/RolesService";
import {UserRoleType} from "../../authentication/UserRoleType";
import {User} from "../../api/entities/User";

interface SalesBusiness {
    business: Business,
    salesAgent?: User
}

export const BusinessesPage: React.FC = () => {
    const getBusinessesQuery = useGetBusinessesQuery({salesId: ""});
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [filteredBusinesses, setFilteredBusinesses] = useState<SalesBusiness[]>([]);
    const [search, setSearch] = useState<string>('');
    const isAdmin = rolesService.userInRoles([UserRoleType.Administrator, UserRoleType.GlobalAdministrator])
    const getAllUsersRequest = useGetUsersQuery(undefined, {skip: !isAdmin});

    useEffect(() => {
        if(getBusinessesQuery.data) {
            setBusinesses(getBusinessesQuery.data);
        }
    }, [getBusinessesQuery.data]);

    useEffect(() => {
        const salesBizs = businesses.map(b => {
            const foundUser = getAllUsersRequest.data?.find(u => u.id === b.salesPersonId);
            return {
                business: b,
                salesAgent: foundUser
            }
        })
        const filtered = salesBizs.filter(b => search.length === 0 || b.business.name.toLowerCase().includes(search.toLowerCase()) || b.business.email.toLowerCase().includes(search.toLowerCase()) || ((b.salesAgent?.firstName ?? '') + ' ' + (b.salesAgent?.lastName ?? '')).toLowerCase().includes(search.toLowerCase()));
        setFilteredBusinesses(filtered);
    }, [search, businesses, getAllUsersRequest.data]);

    const navigation = useNavigate();

    const pagination = {
        initialPageSize: 25,
        pageSizeOptions: [25, 50, 100],
    };

    const getColumns : () => EuiBasicTableColumn<SalesBusiness>[] = () => [
        {
            name: 'Name',
            render: (b: SalesBusiness) => <EuiLink onClick={() => {
                navigation(`/business/${b.business.id}`)
            }}>{b.business.name}</EuiLink>,
            sortable: (b: SalesBusiness) => b.business.name
        },
        {
            name: 'Email',
            render: (b: SalesBusiness) => b.business.email,
            sortable: (b: SalesBusiness) => b.business.email
        },
        {
            name: 'Created  Date',
            render: (b: SalesBusiness) => moment(b.business.createdAt).format('MM/DD/YYYY'),
            sortable: (b: SalesBusiness) => moment(b.business.createdAt).format('MM/DD/YYYY')
        },
        ...isAdmin && getAllUsersRequest.data ? [
            {
                name: 'Sales Rep',
                field: '',
                render: (b: SalesBusiness) => {
                    if (b.salesAgent) {
                        return `${b.salesAgent.firstName} ${b.salesAgent.lastName}`;
                    }
                    return '';
                },
            }
        ] : [
        ]
    ]

    return (
        <EuiPage>
            <EuiPageBody>
                <EuiPageSection>
                    <EuiPageHeader pageTitle={"Businesses"} description={"Manage your clients"}/>
                    <EuiSpacer />
                    <EuiFormRow>
                        <EuiButton onClick={() => navigation('/new')}>New Business</EuiButton>
                    </EuiFormRow>
                    <EuiSpacer />
                    <EuiFormRow>
                        <EuiFieldSearch value={search} onChange={e => setSearch(e.target.value)}/>
                    </EuiFormRow>
                    <EuiSpacer/>
                    <EuiInMemoryTable
                        tableCaption="Demo of EuiInMemoryTable with uncontrolled selection"
                        items={filteredBusinesses}
                        itemId="id"
                        columns={getColumns()}
                        pagination={pagination}
                        sorting={true}
                    />
                </EuiPageSection>
            </EuiPageBody>
        </EuiPage>
    )
}
import {
  AuthContext,
  getDefaultAuthContextValue,
  IAuthContextValue,
} from "./AuthContext";
import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import {dsApi, publicAxios} from "api/rtkQueryApi/DsApi";
import {
  clearSessionStorage,
  getAccessToken,
  setAccessToken,
  setUserRoles,
} from "../localStorage/LocalStoreManagerService";
import {useDispatch} from "react-redux";

export const AuthenticationProvider: FC<PropsWithChildren> = (props) => {
  const dispatch = useDispatch()
  const [authContextValue, setAuthContextValue] = useState<IAuthContextValue>(
    getDefaultAuthContextValue(),
  );

  const axiosRef = useRef(publicAxios());

  useEffect(() => {
    const token = getAccessToken();
    setAuthContextValue((prevState) => ({
      ...prevState,
      isAuthenticated: !!token,
      login: authenticate,
      logout: logout,
    }));
  }, []);

  const logout = () => {
    setAccessToken("");
    setAuthContextValue((prevState) => ({
      ...prevState,
      isAuthenticated: false,
    }));
    dispatch(dsApi.util.resetApiState())
    clearSessionStorage();
  };

  const authenticate = (
    email: string,
    password: string,
    onSuccess?: () => void,
  ) => {
    setAuthContextValue((prevState) => ({ ...prevState, isLoading: true }));
    axiosRef.current
      .request({
        url: "login",
        method: "POST",
        data: {
          email: email,
          password: password,
        },
        params: {},
      })
      .then((response) => {
        if (response.status === 200) {
          setAccessToken(response.data.data.accessToken);
          setUserRoles(response.data.data.roles);
          setAuthContextValue((prevState) => ({
            ...prevState,
            isAuthenticated: true,
            isLoading: false,
          }));
          onSuccess?.();
        }
      })
      .catch(() => {
        setAuthContextValue((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

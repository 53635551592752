import {EuiFlexGroup, EuiFlexItem} from "@elastic/eui";
import {Header} from "./Header";
import {Footer} from "./Footer";
import React from "react";
import {Outlet} from "react-router-dom";
import {useAuthenticator} from "../authentication/useAuthenticator";
import {LoginPanel} from "../components/LoginPanel";

export const Root: React.FC = () => {
    const {isAuthenticated} = useAuthenticator();

    return (
        <EuiFlexGroup direction={"column"} style={{'height': '100%'}}>
            <EuiFlexItem grow={false}>
                <Header />
            </EuiFlexItem>
            <EuiFlexItem style={{overflow: "auto"}}>
                {isAuthenticated ?
                    <Outlet />
                : <LoginPanel />}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <Footer />
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}
import {combineReducers} from "@reduxjs/toolkit";
import {NewOrderCreateSlice} from "./NewOrderCreateSlice";
import {dsApi} from "../api/rtkQueryApi/DsApi";
import {intuitApi} from "../api/rtkQueryApi/IntuitApi";

export const RootReducer = combineReducers({
    newOrder: NewOrderCreateSlice.reducer,
    [dsApi.reducerPath]: dsApi.reducer,
    [intuitApi.reducerPath]: intuitApi.reducer
})

export type RootState = ReturnType<typeof RootReducer>
import {
    EuiHeader,
    EuiHeaderSection,
    EuiHeaderSectionItem,
    EuiHeaderLink,
    EuiHeaderLinks,
    EuiHeaderLogo,
    EuiButton,
    EuiLink,
    EuiBadge,
    EuiImage
} from "@elastic/eui";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuthenticator} from "../authentication/useAuthenticator";
import {rolesService} from "../localStorage/RolesService";
import {UserRoleType} from "../authentication/UserRoleType";

export const Header: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isAuthenticated, logout} = useAuthenticator();
    const isAdmin = rolesService.userInRoles([UserRoleType.Administrator, UserRoleType.GlobalAdministrator])

    const onLogoClick = () => {
        onBusinessClick();
    };

    const onBusinessClick = () => {
        navigate("/");
    };

    const onUsersClick = () => {
        navigate("/users");
    };

    const onLogoutClick = () => {
        logout();
    };

    return (
        <EuiHeader>
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <EuiLink>
                        <EuiHeaderLogo iconType={() => <EuiImage size={'m'} src='/Decibel130_Main_Top.jpg' alt={''}
                                                                 style={{width: 100}}/>} onClick={onLogoClick}/>
                    </EuiLink>
                </EuiHeaderSectionItem>
                {isAdmin && (
                    <EuiHeaderSectionItem>
                        <EuiBadge color={'success'} style={{marginRight: 16}}><p>Admin</p></EuiBadge>
                    </EuiHeaderSectionItem>
                )
                }
                <EuiHeaderSectionItem>
                    {isAuthenticated && (
                        <EuiHeaderLinks aria-label="App navigation links example">
                            <EuiHeaderLink
                                isActive={location.pathname === "/"}
                                onClick={onBusinessClick}
                            >
                                Businesses
                            </EuiHeaderLink>
                            {isAdmin && (
                                <EuiHeaderLink
                                    isActive={location.pathname.toLowerCase().startsWith("/users")}
                                    onClick={onUsersClick}
                                >
                                    Users
                                </EuiHeaderLink>
                            )}
                        </EuiHeaderLinks>
                    )}
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    {isAuthenticated && (
                        <EuiButton onClick={onLogoutClick}>Logout</EuiButton>
                    )}
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
        </EuiHeader>
    );
};

import {useSelector} from "react-redux";
import {RootState} from "../../slices/RootReducer";
import {useEffect, useState} from "react";
import {OrderType} from "../../api/entities/Addon";
import {BusinessBuilder} from "../../slices/NewOrderCreateSlice";
import {ProductDetails} from "../../api/entities/Product";
import {GetTotalOneTimePrice} from "../../utils/PlanUtils";
import {GetAddonPrice} from "../../utils/ProductUtils";

export interface AddonLedgerItem {
    name: string;
    quantity: number;
    pricePerUnit: number;
    oneTimePrice: number;
    recurringPricePerUnit: number;
    recurringPrice: number;
}

export interface INewOrderDetails {
    totalOneTimePrice: number;
    totalMonthlyPrice?: number;
    addonLedger: AddonLedgerItem[];
    productDetails?: ProductDetails
}

export const useNewOrderDetails = () : INewOrderDetails => {
    const {businessBuilder} = useSelector((state: RootState) => state.newOrder);
    const [totalOneTimePrice, setTotalOneTimePrice] = useState(0);
    const [totalMonthlyPrice, setTotalMonthlyPrice] = useState(0);
    const [addonLedger, setAddonLedger] = useState<AddonLedgerItem[]>([]);

    const getOneTimePrice = (builder: BusinessBuilder) => {
        if(!builder.product) {
            return 0;
        }
        return GetTotalOneTimePrice(builder.product, builder.addons ?? [], builder.productGroupId);
    }

    const getAddonLedger = (builder: BusinessBuilder) : AddonLedgerItem[] => {
        const ledger : AddonLedgerItem[] = [];
        if (builder.addons) {
            builder.addons.forEach(addon => {
                const oneTimePrice = addon.orderType === OrderType.OneTime ? GetAddonPrice(addon, builder.productGroupId) : 0;
                const recurringPrice = addon.orderType === OrderType.Recurring ? GetAddonPrice(addon, builder.productGroupId) : 0;
                const existing = ledger.find(l => l.name === addon.name);
                if (existing) {
                    existing.quantity++;
                    existing.oneTimePrice += oneTimePrice;
                    existing.recurringPrice += recurringPrice;
                } else {
                    ledger.push({
                        name: addon.name,
                        quantity: 1,
                        pricePerUnit: oneTimePrice,
                        oneTimePrice: oneTimePrice,
                        recurringPricePerUnit: recurringPrice,
                        recurringPrice: recurringPrice
                    })
                }
            })
        }
        return ledger;
    }

    useEffect(() => {
        setTotalOneTimePrice(getOneTimePrice(businessBuilder));
        setAddonLedger(getAddonLedger(businessBuilder));
    }, [businessBuilder]);

    useEffect(() => {
        let totalMonthly = 0;
        addonLedger.forEach(addon => {
            totalMonthly += addon.recurringPrice;
        });
        if (businessBuilder.product) {
            totalMonthly += businessBuilder.product.contractLengthMonths > 1 ? businessBuilder.product.pricePerMonth : 0
        }
        setTotalMonthlyPrice(totalMonthly);
    }, [addonLedger, businessBuilder.product]);

    return {
        totalOneTimePrice: totalOneTimePrice,
        totalMonthlyPrice: totalMonthlyPrice,
        addonLedger: addonLedger,
        productDetails: businessBuilder.product
    }
}
import { createContext } from "react";

export interface IAuthContextValue {
  isAuthenticated: boolean;
  login: (email: string, password: string, onSuccess?: () => void) => void;
  logout: () => void;
  isLoading: boolean;
}

export const getDefaultAuthContextValue: () => IAuthContextValue = () => {
  return {
    isAuthenticated: false,
    login: () => {},
    logout: () => {},
    isLoading: false,
  };
};

export const AuthContext = createContext(getDefaultAuthContextValue());

import {Plan} from "../../api/entities/Plan";
import {FC} from "react";
import {EuiFlexGroup, EuiFlexItem, EuiPanel, EuiStat} from "@elastic/eui";
import {PlansGetTotalRecurringPrice} from "../../utils/PlanUtils";
import {NumberToCurrency} from "../../utils/Formatters";

export interface IPlansStatGroup {
    plans: Plan[];
}

export const PlansStatGroup : FC<IPlansStatGroup> = props => {
    const totalMonthlySpend = PlansGetTotalRecurringPrice(props.plans);

    return (
        <EuiFlexGroup>
            <EuiFlexItem grow={false}>
                <EuiPanel hasShadow={false} hasBorder={true} style={{width: 240}}>
                    <EuiStat description={'Total Monthly Spend'} title={`${NumberToCurrency(totalMonthlySpend)}`}/>
                </EuiPanel>
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}
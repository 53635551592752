import {AddonDetails} from "../../api/entities/Addon";
import {EuiButton, EuiCard, EuiSpacer, EuiText} from "@elastic/eui";
import {GetAddonPrice} from "../../utils/ProductUtils";
import {NumberToCurrency} from "../../utils/Formatters";

export interface IAddonDetailsCard {
    addonDetails: AddonDetails
    onAddonSelected?: () => void;
    productGroupId?: string;
}

export const AddonDetailsCard: React.FC<IAddonDetailsCard> = (props) => {

    const price = GetAddonPrice(props.addonDetails, props.productGroupId);

    return (
        <EuiCard title={props.addonDetails.name} description={props.addonDetails.description} style={{width: 400}}>
            <EuiSpacer />
            {price > 0 && <EuiText>{NumberToCurrency(price)}</EuiText>}
            {price !== props.addonDetails.price && <EuiText>(${(props.addonDetails.price - price).toLocaleString()} saved)</EuiText>}
            <EuiSpacer/>
            <EuiButton fill fullWidth onClick={props.onAddonSelected}>Add</EuiButton>
        </EuiCard>
    );
}
import {Plan} from "../api/entities/Plan";
import {AddonDetails, OrderType} from "../api/entities/Addon";
import {ProductDetails} from "../api/entities/Product";
import {GetAddonPrice} from "./ProductUtils";

export const PlanGetTotalRecurringPrice = (plan: Plan) => {
    let totalMonthly = 0;
    plan.addonDetailsList.forEach(addon => {
        if(addon.orderType === OrderType.Recurring) {
            totalMonthly += addon.price;
        }
    });

    if(plan.schedule.totalIntervals > 1) {
        totalMonthly += plan.productDetails.pricePerMonth;
    }
    return totalMonthly;
}

export const PlanGetTotalOneTimePrice = (plan: Plan) => {
    let totalOneTime = 0;
    plan.addonDetailsList.forEach(addon => {
        if(addon.orderType === OrderType.OneTime) {
            totalOneTime += addon.price;
        }
    });
    return totalOneTime;
}

export const GetTotalOneTimePrice = (product: ProductDetails, addons: AddonDetails[], productGroupId?: string) => {
    let totalOneTime = product.pricePerMonth;
    addons.forEach(addon => {
        if(addon.orderType === OrderType.OneTime) {
            totalOneTime += GetAddonPrice(addon, productGroupId);
        }
    });
    return totalOneTime;
}

export const PlansGetTotalOneTimePrice = (plans: Plan[]) => {
    let totalOneTime = 0;
    plans.forEach(plan => {
        totalOneTime += PlanGetTotalOneTimePrice(plan);
    });
    return totalOneTime;
}

export const PlansGetTotalRecurringPrice = (plans: Plan[]) => {
    let totalMonthly = 0;
    plans.forEach(plan => {
        totalMonthly += planIsActive(plan) && plan.schedule.totalIntervals > 1 ? PlanGetTotalRecurringPrice(plan) : 0;
    });
    return totalMonthly;
}

export const planIsActive = (plan: Plan) => {
    return plan.status === 'Active' || plan.status === 'Ending';
}
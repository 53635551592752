import {AddonDetails} from "../../api/entities/Addon";
import {EuiButton, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle} from "@elastic/eui";
import {GetAddonPrice} from "../../utils/ProductUtils";
import {NumberToCurrency} from "../../utils/Formatters";

export interface IAddonPurchasePanel {
    addonDetails: AddonDetails
    quantity?: number
    productGroupId?: string
    onRemove?: () => void
}

export const AddonPurchasePanel: React.FC<IAddonPurchasePanel> = (props) => {
    let price = GetAddonPrice(props.addonDetails, props.productGroupId)
    price = price * (props.quantity || 1)

    return (
        <EuiPanel hasShadow={false} hasBorder={true}>
            <EuiTitle size={'s'}>
                <h2>{props.addonDetails.name}{props.quantity && props.quantity > 1 ? ` x ${props.quantity}` : '' }</h2>
            </EuiTitle>
            <EuiText size={'s'}><p>{props.addonDetails.description}</p></EuiText>
            <EuiSpacer />
            {price > 0 && <EuiText>{NumberToCurrency(price)}</EuiText>}
            <EuiSpacer/>
            <EuiFlexGroup>
                <EuiFlexItem/>
                <EuiFlexItem grow={false}>
                    <EuiButton fill onClick={props.onRemove} color={'danger'}>Remove</EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>
    )
}
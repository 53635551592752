import {ProductGroup} from "api/entities/ProductGroup";
import {EuiButton, EuiCard, EuiFlexGroup, EuiFlexItem} from "@elastic/eui";
import {Product} from "api/entities/Product";

export interface IProductGroupCard {
    productGroup: ProductGroup
    selectedProductId?: string
    onProductSelected?: (product: Product) => void
}

export const ProductGroupCard : React.FC<IProductGroupCard> = props => {

    const group = props.productGroup

    const onProductClick = (product: Product) => {
        props.onProductSelected?.(product)
    }

    return (
        <EuiCard
            id={group.id}
            title={group.name}
            description={group.description}
            style={{width: 600}}
        >
            <EuiFlexGroup direction={'column'}>
                <EuiFlexItem/>
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup alignItems={'center'} justifyContent={'center'}  wrap>
                        {group.products.map(product => {
                            return (
                                <EuiFlexItem grow={false}>
                                    <EuiButton color={props.selectedProductId === product.id ? 'success' : 'primary'} onClick={() => onProductClick(product)}>{product.name}</EuiButton>
                                </EuiFlexItem>
                            )
                        })}
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem/>
            </EuiFlexGroup>
        </EuiCard>
    );
}
import { UserCreatePanel } from "../../components/users/UserCreatePanel";
import { useNavigate } from "react-router-dom";

export const NewUser: React.FC = () => {
  const navigate = useNavigate();

  const onUserCreated = () => {
    navigate("/users");
  };
  return <UserCreatePanel onUserCreated={onUserCreated} />;
};

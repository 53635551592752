import {BaseQueryFn} from "@reduxjs/toolkit/query";
import {AxiosError, AxiosRequestConfig} from "axios";
import {publicAxios, securedContextAxios} from "./DsApi";

export interface BaseQueryFnProps {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
    isPublic?: boolean
}

export const axiosBaseQuery =
    (): BaseQueryFn<BaseQueryFnProps, unknown, unknown, {}, {}> =>
        async ({ url, method, data, params, isPublic }) => {
            try {
                let axiosInstance
                if (isPublic) {
                    axiosInstance = publicAxios()
                } else {
                    axiosInstance = securedContextAxios()
                }

                const result = await axiosInstance.request({ url, method, data, params })

                return {
                    data: result.data
                }
            } catch (axiosError) {
                const err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message
                    }
                }
            }
        }
